* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Text";
  src: url(./fonts/A2Beckett-Semibold.woff2) format("woff2"),
    url(./fonts/A2Beckett-Semibold.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: #000;
  color: #fff;
  text-align: center;
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  /* height: 100vh; */
}

h1 {
  text-transform: uppercase;
  font-family: "Text";
  font-size: 23vw;
  line-height: 1;
  margin: 0;
  font-weight: 900;
}

h2 {
  font-size: 1.1rem;
  font-family: "Text";
  text-transform: uppercase;
}

.app {
  opacity: 0;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: opacity 0.4s ease-in;
  min-height: 580px;
}

.app.opacity-1 {
  opacity: 1;
}

.rip {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  width: 130%;
  margin: 0 auto;
  margin-bottom: -1px;
  opacity: 0.92;
  pointer-events: none;
}

.texture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  mix-blend-mode: soft-light;
  opacity: 25%;
  pointer-events: none;
}

.app__text .logo {
  width: 30vw;
  height: auto;
  margin-bottom: -0.4rem;
}

.app__text {
  position: relative;
  z-index: 2;
  margin-top: -3rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mailing {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 4rem 0;
  font-family: "Text";
  text-transform: uppercase;
  padding: 0 2rem;
  margin-bottom: 6rem;
  z-index: 2;
}

.mailing p {
  font-family: "Text";
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 280px;
  display: block;
}

.mailing .success {
  color: #05b012 !important;
}

.mailing form {
  all: unset;
  display: flex;
  width: 90%;
  margin: 0 auto;
  max-width: 440px;
}

.mailing input {
  all: unset;
  background-color: #fff;
  color: #000;
  padding: 0.8rem !important;
  padding-top: 0.92rem !important;
  flex: 1;
  text-align: left;
  font-size: 1.1rem !important;
}

.mailing button[type="submit"] {
  all: unset;
  background-color: #3151f8;
  color: #fff;
  padding: 0.8rem 1.2rem !important;
  padding-top: 0.92rem !important;
  font-size: 1.1rem !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.mailing button[type="submit"]:hover {
  color: #b7bfe7;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  filter: brightness(0.5);
}

.socials {
  margin-top: 0.5rem;
}

.socials svg {
  font-size: 1.5rem;
  margin: 0 0.5rem;
  color: #fff;
}

.socials a {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.socials a:hover {
  opacity: 0.7;
}

@media screen and (min-width: 600px) {
  h1 {
    font-size: 22vw;
  }

  .app__text .logo {
    width: 22vw;
  }

  .mailing {
    margin: 7rem 0;
  }

  .mailing p {
    max-width: 440px;
  }

  .app {
    min-height: 680px;
  }
}

@media screen and (min-width: 768px) {
  .app {
    min-height: 100vh;
  }
  h2 {
    font-size: 1.2rem;
  }
  h1 {
    font-size: 18vw;
  }
  .app__text .logo {
    width: 16vw;
  }

  .socials svg {
    font-size: 2rem;
  }

  .app {
    min-height: 680px;
  }
}

@media screen and (min-width: 1024px) {
  h1 {
    font-size: 16vw;
  }
  h2 {
    font-size: 1.3rem;
  }
  .app__text .logo {
    width: 12vw;
  }

  .rip {
    width: 100%;
  }

  .mailing p {
    font-size: 1.1rem !important;
  }

  .mailing input {
    padding: 1rem;
    flex: 1;
    font-size: 1.2rem !important;
  }

  .mailing button[type="submit"] {
    padding: 1rem 1.4rem;
    font-size: 1.2rem !important;
  }

  .app {
    min-height: 780px;
  }
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 14vw;
  }
  .app__text .logo {
    width: 11vw;
  }

  .socials svg {
    margin: 0 0.8rem;
  }

  .mailing {
    margin: 8.5rem 0;
  }

  .mailing input {
    padding: 1rem 1.6rem;
  }
}

@media screen and (min-width: 1440px) {
  h1 {
    font-size: 13rem;
  }
  .app__text .logo {
    width: 10rem;
    margin-bottom: -0.7rem;
  }

  .app__text {
    margin-top: -5rem;
  }

  .rip {
    margin-bottom: -2rem;
  }

  .mailing {
    margin: 8.2rem 0;
  }
}
